import * as Sentry from "@sentry/node";
import { AppProps } from "next/app";
import { analytics } from "@project/shared";
import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { useRouter } from "next/router";
import { Layout, message } from "antd";
import { SideNav } from "../components/organisms/SideNav";
import styled from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "../utils/AuthContext";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import BrowserNotSupported from "./browser-not-supported";
import firebase from "firebase";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
const { Sider } = Layout;

const LayoutWrapper = styled.div`
  width: calc(100vw - 215px);
  & .ant-layout-header {
    height: 54px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  & .ant-layout-content {
    padding: 30px;
  }
`;

declare global {
  interface Document {
    documentMode?: any;
  }
}

export { Layout };

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `kowapets-frontend-admin-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

message.config({
  duration: 4.5,
  maxCount: 3,
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter();
  const [collapse, setCollapse] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null as firebase.User | null);
  const [isAdmin, setIsAdmin] = useState(false);
  const queryClient = new QueryClient();
  const [isIE, setIsIE] = useState(false);
  const { t } = useTranslation();
  const restrictedRoute = [
    "/login",
    "/forgot-password",
    "/forgot-password/done",
  ];

  useEffect(() => {
    const isIE = !!window.document.documentMode;
    if (isIE) {
      setIsIE(true);
    }
  }, []);

  useEffect(() => {
    setCollapse(collapse);
  }, [collapse]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url);
        analytics().logEvent("screen_view", {
          screen_name: url,
          app_name: "Kowapets",
        });
      };

      routers.events.on("routeChangeComplete", logEvent);
      logEvent(window.location.pathname);
      return () => {
        routers.events.off("routeChangeComplete", logEvent);
      };
    }
  }, []);

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (user !== null) {
          const token = await user.getIdTokenResult(true);
          const decodedToken = jwt_decode(token.token);
          Sentry.setUser({ email: decodedToken["email"] });
          setUser(user);
          setIsAdmin(decodedToken["role"] == "admin");
          if (decodedToken["deactive"]) {
            firebase.auth().signOut();
            setUser(null);
            message.error(t("Unauthorized user"));
          }
        }
        setLoading(false);
      } catch (error) {
        message.error(t(`${"Error occurred"}`));
        Sentry.captureException(error);
      }
    });
  };
  useEffect(() => {
    initialLoad();
  }, []);

  if (isIE) {
    return <BrowserNotSupported />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        loading={loading}
        user={user}
        isAdmin={isAdmin}
        setUser={setUser}
      >
        {!restrictedRoute.includes(routers.pathname) && user ? (
          <Layout>
            <Sider trigger={null} collapsed={false}>
              <SideNav />
            </Sider>
            <LayoutWrapper>
              <Component {...pageProps} />
            </LayoutWrapper>
          </Layout>
        ) : (
          <Component {...pageProps} />
        )}
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default MyApp;
