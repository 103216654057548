import React, { useContext } from "react";
import Router, { useRouter } from "next/router";
import { firebase, MenuGenerator, theme } from "@project/shared";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CompanyLogo from "../../../assets/logo.png";
import { AuthContext } from "../../../utils/AuthContext";
import * as Sentry from "@sentry/node";

const Wrapper = styled.div`
  background-color: #373e49;
  position: sticky;
  top: 0px;
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #232a32;
    color: #fff;
  }
  .ant-menu-item:hover {
    background: #232a32;
    color: #fff;
  }
  .ant-menu {
    background: #2c343f;
    font-size: $sidebar-menu-font;
    height: calc(100vh - 54px);
  }
  .ant-menu-item {
    color: #d3d3d3;
  }
  .ant-menu-inline .ant-menu-item::after {
    position: absolute;
    right: auto !important;
    top: 0;
    left: 0;
    border-right: 3px solid #65b798;
  }
`;

const SideNavHeadWrapper = styled.div`
  height: 54px;
  font-size: 14px;
  background: #f8a800;
  text-align: center;
  box-shadow: inset 0px -1px 0px #373e49;
  & .title {
    font-weight: normal;
    line-height: 64px;
    color: ${theme.base};
    text-align: center;
  }
`;

const SideNav = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const selectKey = router.pathname.split("/");
  if (selectKey[1] === "menu1-submenu1") selectKey[1] = "menu1";
  const selectKeyDefault = selectKey[1] || "menu1";

  const { setUser, isAdmin } = useContext(AuthContext);

  const handleSignOut = async () => {
    await firebase.auth().signOut();
    setUser(null);

    Sentry.configureScope((scope) => scope.setUser(null));
    Router.push("/login");
  };

  const menus = [
    {
      className: null,
      key: "1",
      onClick: () => router.push("/"),
      icon: null,
      name: t("ペット"),
    },
    {
      className: null,
      key: "2",
      onClick: () => router.push("/voices"),
      icon: null,
      name: t("お客様の声"),
    },
    {
      className: null,
      key: "3",
      onClick: () => router.push("/news"),
      icon: null,
      name: t("お知らせ"),
    },
    {
      className: null,
      key: "4",
      onClick: () => router.push("/inquiries"),
      icon: null,
      name: t("お問い合わせ"),
    },
    {
      className: null,
      key: "5",
      onClick: () => router.push("/store-comment"),
      icon: null,
      name: t("店舗のコメント"),
      staff: true,
    },
    {
      className: null,
      key: "6",
      onClick: () => router.push("/picture-book"),
      icon: null,
      name: t("図鑑"),
      admin: true,
    },
    {
      className: null,
      key: "7",
      onClick: () => router.push("/shops"),
      icon: null,
      name: t("店舗"),
      admin: true,
    },
    {
      className: null,
      key: "8",
      onClick: () => router.push("/columns"),
      icon: null,
      name: t("コラム"),
      admin: true,
    },
    {
      className: null,
      key: "9",
      onClick: () => router.push("/top-images"),
      icon: null,
      name: t("トップ画像"),
      admin: true,
    },
    {
      className: null,
      key: "10",
      onClick: () => router.push("/email-setting"),
      icon: null,
      name: t("メールの宛先設定"),
      admin: true,
    },
    {
      className: null,
      key: "11",
      onClick: () => router.push("/solicitation-policy"),
      icon: null,
      name: t("勧誘方針"),
      admin: true,
    },
    {
      className: null,
      key: "12",
      onClick: handleSignOut,
      icon: null,
      name: t("ログアウト"),
    },
  ];

  return (
    <Wrapper>
      <SideNavHeadWrapper>
        <strong className="title">
          <img src={CompanyLogo} style={{ paddingBottom: "10px" }} />
        </strong>
      </SideNavHeadWrapper>
      <MenuGenerator
        mode="inline"
        defaultSelectedKeys={[selectKeyDefault]}
        items={
          isAdmin
            ? menus.filter((menu) => !menu.staff)
            : menus.filter((menu) => !menu.admin)
        }
      />
    </Wrapper>
  );
};

export { SideNav };
